
// var SUCCESS_CODES = "200, 201, 204";

// export const BACKEND_URL = "http://127.0.0.1:8000";
// export const BACKEND_URL = "https://artemisvision.herokuapp.com";
export const BACKEND_URL = "https://d264tekgsemw28.cloudfront.net"

export const IPFS_MEDIA_URL = "https://gateway.pinata.cloud/ipfs/"
export const S3_URL = "https://artemisvision.s3.us-east-2.amazonaws.com/"
export const APP_URL = window.location.href.split("#")[0] //  "https://artemis-vision-app.web.app";
export var REELS_URL =   "https://artemis-reel.web.app/";
// 
export const BLOCKCHAIN_NETWORK = 'mainnet'