import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';



Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
});


const opts = {
  customVariables: ['../../sass/variables.scss'],
  icons: {
    iconfont: 'mdi',
    values: {
      custom: { // name of our custom icon
      },
    },
  },
  treeShake: true,
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#b918f4',
        secondary: '#007af6',
        accent: '#8c9eff',
        error: '#FF6868',
      },
      dark: {
        primary: '#b918f4',
        secondary: '#007af6',
        accent: '#8c9eff',
        error: '#FF6868',
      },
    },
  },
}

let vuetify = new Vuetify(opts);

export default vuetify;
