<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="main fluid">
      <!-- Frames Start -->
      <div class="animated-scene">
        <div class="animated-scene_frame frame-1">
          <img src="images/img_1.png" />
        </div>
        <div class="animated-scene_frame frame-2">
          <img src="images/img_2.png" />
        </div>
        <div class="animated-scene_frame frame-3">
          <img src="images/img_3.png" />
        </div>
        <div class="animated-scene_frame frame-4">
          <img src="images/img_4.png" />
        </div>
        <div class="animated-scene_frame frame-5">
          <img src="images/img_5.png" />
        </div>
      </div>
      <!-- Frames End -->

      <!-- Contents Start -->
      <v-overlay
        color="black"
        :dark="false"
        absolute
        :opacity="0.5"
        style="z-index: 0 !important"
      >
      </v-overlay>

      <div class="d-flex flex-column justify-center align-stretch">
        <div style="z-index: 2 !important">
          <v-container>
            <!-- <v-row justify="center" align="center">
              <v-col cols="12"> -->
            <router-view></router-view>
            <!-- </v-col>
            </v-row> -->
          </v-container>
        </div>
        <div
          class="d-flex justify-center flex-column"
          style="position: relative"
        >
          <div class="d-flex justify-center">
            <a href="https://twitter.com/artemisvision_" target="_blank">
              <v-btn small color="white" outlined icon rounded class="mx-2"
                ><v-icon small color="white">mdi-twitter</v-icon></v-btn
              >
            </a>
            <a href="https://www.facebook.com/artemisvision.io" class="mr-2" target="_blank">
              <v-btn small color="white" outlined icon rounded
                ><v-icon small color="white"> mdi-facebook</v-icon></v-btn
              >
            </a>
            <a
              href="https://www.youtube.com/channel/UCDhf4COdvtRQ82sFuyp-75w"
              target="_blank"
            >
              <v-btn small color="white" outlined icon rounded
                ><v-icon small color="white">mdi-youtube</v-icon></v-btn
              >
            </a>
          </div>
          <div
            class="d-flex justify-center align-center mt-3 mx-auto white--text"
          >
            <a class="link" href="#">Privacy Policy</a>
            <span class="mx-4"></span>
            <a class="link" href="#">Terms and Conditions</a>
          </div>
          <p class="text-center white--text mt-1 mb-0">
            &copy; {{ new Date().getFullYear() }} Artemis Vision. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- </v-container> -->
</template>

<script>
// import Navbar from "./NavBar.vue";

export default {
  components: {
    // navbar: Navbar,
  },
};
</script>


<style scoped  src="@/assets/css/style.css">
</style>
<style scoped>
.container.fullOverlay {
  padding: 0 !important;
}

.gradientFill {
  background: linear-gradient(45deg, #da07a2, #5d44a5 47%, #35b0e6) !important;
}

@media (max-width: 1024px) {
  .gradientFill {
    height: 200px;
  }
}

.v-overlay {
  z-index: 0 !important;
}
a.link {
  text-decoration: none;
  color: whitesmoke;
}

a.link:hover {
  color: #7668fe;
}
</style>
